import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><br />
      <MDXTag name="p" components={components}>{`This story fell into my lap today and I liked it so I thought hey, why not fire up the ole' blog and make sure you still know how to deploy the damn thing.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Currently bemoaning my own lack of documentation.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Hope you enjoy too!`}</MDXTag>
      <MDXTag name="p" components={components}>{`Article: `}<a target="_blank" rel="nofollow" href="https://leahneukirchen.org/blog/archive/2019/10/ken-thompson-s-unix-password.html">{`Ken Thompson's Unit password`}</a></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "path": "/2019-10-17-ken-thompson-password-anecdote",
  "date": "2019-10-17",
  "title": "The One About Ken Thompson's Unix Password",
  "image": "2019-10-17-ken-thompson-password-anecdote.jpeg",
  "sharpImage": true,
  "imgOverlay": "linear-gradient(to bottom,rgb(0,0,0,0.4),rgb(67,67,67,0.1))",
  "tags": ["encryption", "ken", "password"],
  "excerpt": "This story fell into my lap today and I liked it, so I thought hey why not fire up the old blog and make sure you still know how to deploy. Hope you enjoy too!",
  "published": true
};
      